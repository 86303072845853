<template>
  <div>
    <b-modal
      ref="modalNotificacao"
      size="lg"
      modal-class="custom-modal"
      centered
      hide-header
      :title="''"
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
    >
      <!-- <div
        class="notification-content"
        :class="`notification-${notificacao.type}`"
      > -->
      <div v-html="obterHtmlCustomizadoNotificacao(notificacao.type)"></div>
      <div class="notification-message">
        <!-- <h5>{{ notificacao.title }}</h5> -->
        <div
          v-if="notificacao.text"
          class="notification-text text-center"
          v-html="notificacao.text"
        ></div>
      </div>
      <!-- </div> -->

      <template #modal-footer>
        <CustomButton
          buttonText="Fechar"
          variant="light"
          @button-click="fecharModal"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  name: "NotificacaoCustomizada",
  mixins: [Mixin],
  data() {
    return {
      notificacao: {
        title: "",
        text: "",
        type: "info",
        duration: 5000,
      },
      timerNotificacao: null,
    };
  },
  methods: {
    /**
     * Exibe uma notificação personalizada
     * @param {Object} options - Opções da notificação
     * @param {String} options.title - Título da notificação
     * @param {String} options.text - Texto da notificação
     * @param {String} options.type - Tipo da notificação (success, error, info, warn)
     * @param {Number} options.duration - Duração da notificação em milissegundos
     */
    show(options) {
      // Limpa o timer anterior se existir
      if (this.timerNotificacao) {
        clearTimeout(this.timerNotificacao);
      }

      // Configura a notificação com os valores padrão e os fornecidos
      this.notificacao = {
        title: options.title || "",
        text: options.text || "",
        type: options.type || "info",
        duration: options.duration || 5000,
      };

      // Exibe o modal
      this.$refs.modalNotificacao.show();

      // Configura o timer para fechar automaticamente
      if (this.notificacao.duration > 0) {
        this.timerNotificacao = setTimeout(() => {
          this.$refs.modalNotificacao.hide();
        }, this.notificacao.duration);
      }
    },

    /**
     * Fecha o modal de notificação
     */
    fecharModal() {
      this.$refs.modalNotificacao.hide();
    },

    obterHtmlCustomizadoNotificacao(tipo) {
      const tiposDialogo = {
        //dashlite icons
        error: `
                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
                        <span class="swal2-x-mark">
                            <span class="swal2-x-mark-line-left"></span>
                            <span class="swal2-x-mark-line-right"></span>
                        </span>
                    </div>
                `,
        success: `
                    <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;"><div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
                        <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
                    </div>
                `,
        danger: `
                    <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;"><span class="swal2-x-mark">
                            <span class="swal2-x-mark-line-left"></span>
                            <span class="swal2-x-mark-line-right"></span>
                        </span>
                    </div>
                `,
        warning: `
                    <div class="swal2-icon swal2-warning swal2-icon-show" style="display: flex;">
                        <div class="swal2-icon-content">!</div>
                    </div>
                `,
        info: `
                    <div class="swal2-icon swal2-info swal2-icon-show" style="display: flex;">
                        <div class="swal2-icon-content">i</div>
                    </div>
                `,
        loading: `
                    <div class="text-center">  <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">    <span class="visually-hidden"></span>  </div></div>
                `,
      };

      if (tipo && tiposDialogo.hasOwnProperty(tipo)) {
        return tiposDialogo[tipo];
      }
    },
  },
};
</script>

<style>
.notification-content {
  display: flex;
  padding: 15px;
  align-items: flex-start;
}

.notification-icon {
  margin-right: 15px;
  font-size: 24px;
}

.notification-message {
  flex: 1;
}
</style>