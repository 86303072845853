var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.componentKey},[_c('vue-navigation-bar',{class:!_vm.sidebarOpened ? 'sidebar-opened' : '',attrs:{"options":_vm.navbarOptions},on:{"vnb-item-clicked":_vm.changeRoute},scopedSlots:_vm._u([{key:"custom-section",fn:function(){return [(_vm.isMobile())?_c('div',{staticStyle:{"display":"contents"}},[_c('CustomButton',{attrs:{"iconProps":{
            icon: 'list',
            variant: 'light',
            'font-scale': '1.7',
          },"containerProps":{
            cols: '2',
          },"buttonText":"","variant":"outline-light","classButton":"text-white border-0 ml-3 text-left p-1"},on:{"button-click":function($event){return _vm.onToggleCollapse(!_vm.sidebarOpened)}}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"4"}},[_c('ImageThumb',{attrs:{"hasDetailsModal":false,"rounded":false,"altura":42,"thumbnail":false,"errorSrc":require('../assets/mb-solucoes.png'),"src":_vm.lodash.get(
              _vm.$dadosSessao,
              'user.account.logo',
              require('../assets/mb-solucoes.png')
            )}})],1)]},proxy:true}])}),(_vm.exibeMenuLateral)?_c('sidebar-menu',{ref:"menu-lateral",attrs:{"collapsed":true,"menu":_vm.menu,"widthCollapsed":!this.isMobile() ? '60px' : '49px',"hideToggle":false,"rtl":false,"theme":"dark-theme"},on:{"toggle-collapse":_vm.onToggleCollapse,"item-click":function($event){return _vm.onToggleCollapse(true)}}},[_c('span',{staticStyle:{"font-size":"1.5em"},attrs:{"slot":"toggle-icon"},slot:"toggle-icon"},[(!_vm.sidebarOpened)?_c('i',{staticClass:"fa-solid fa-toggle-on mr-3"}):_vm._e(),(_vm.sidebarOpened)?_c('i',{staticClass:"fa-solid fa-toggle-off mr-3"}):_vm._e()]),(!_vm.sidebarOpened)?_c('div',{staticClass:"text-center",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"service-details",staticStyle:{"font-size":"0.9em"}},[_vm._v(" Desenvolvido por "),_c('b',[_vm._v("Skedoole")])])]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }