import api from "../http/index";

class AuthServices {
  async enviarAutenticacao(dados) {
    let { login, password, subdomain, filial = null } = dados;

    let isAuth = false;

    const usuario = {
      password: password,
      subdomain: subdomain,
      login: login,
      headquarterId: filial?.id ?? null,
    };

    const response = await api({
      method: "post",
      url: `/auth/sign_in?key_transform_camel_lower=true`,
      data: {
        ...usuario,
      },
    });

    if (response && response.data.user) {
      isAuth = true;
    }

    let { uid, client, expiry } = response.headers;
    let accessToken = response.headers["access-token"];
    let { data: detalhesUsuarioLogado } = response;

    return {
      isAuth,
      uid,
      accessToken,
      client,
      expiry,
      ...detalhesUsuarioLogado,
    };
  }

  async obterCodigoRedefinicaoSenha({ cellphone, subdomain }) {
    let retorno = await api({
      method: "POST",
      url: `/auth/cellphone_recovery/generate_code`,
      data: {
        cellphone: cellphone,
        subdomain: subdomain,
      },
    });

    return retorno;
  }

  async validarCodigoRedefinicaoSenha({ cellphone, subdomain, code }) {
    let retorno = await api({
      method: "POST",
      url: `/auth/cellphone_recovery/validate_code`,
      data: {
        cellphone: cellphone,
        code: code,
        subdomain: subdomain,
      },
    });

    return retorno;
  }

  async enviarNovaSenhaRecuperacaoSenha({
    senha,
    confirmacaoSenha,
    validacaoCodigo,
  }) {
    let retorno = await api({
      method: "PATCH",
      url: `/auth/password`,
      headers: {
        ...validacaoCodigo,
      },
      data: {
        password: senha,
        password_confirmation: confirmacaoSenha,
      },
    });

    return retorno;
  }

  async criarConta({ user, empresa }) {
    let retorno = await api({
      method: "post",
      url: `externals/users?subdomain=${empresa}`,
      data: {
        user: { ...user },
      },
    });

    return retorno;
  }

  async loginGoogle(dados) {
    let retorno = await api({
      method: "post",
      url: `/oauth/google_oauth2`,
      data: {
        user: { ...dados },
      },
    });

    return retorno;
  }

  async obterInformacoesSubdominio({ empresa }) {
    let retorno = await api({
      method: "get",
      url: `/externals/account_by_subdomain`,
      params: {
        subdomain: empresa,
      },
    });

    return retorno;
  }
}

export default new AuthServices();
