class UtilsComponentePagamento {
  // Add this method to your UtilsPagamento utility
  obterAcoesInfinitePay() {
    return [
      {
        containerProps: {
          cols: "12",
          xl: "12",
          lg: "12",
          md: "12",
          sm: "12",
        },
        iconProps: {
          icon: "currency-exchange",
          variant: "light",
          "font-scale": "1.4",
        },
        buttonText: "Ir para o Pagamento",
        variant: "primary",
        classButton: "text-white",
        click: "this.abrirInifinitePay()",
      },
    ];
  }

  obterInputsPix() {
    return [
      {
        label: "Código PIX",
        id: "payload",
        model: "payload",
        placeholder: "",
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        cols: 12,
        appendButton: true,
        isTextArea: true,
        inputType: "text",
        executeOnPressButtonGroup: `copiarParaAreaDeTransferencia`,
        iconAppendButton: "journals",
        buttonText: "Copiar",
        disabled: true,
      },
      {
        label: "Enviar link de pagamento no WhatsApp",
        id: "cellphonePaymentLink",
        model: "cellphonePaymentLink",
        placeholder: "Informe o numéro do celular",
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        cols: 12,
        appendButton: true,
        inputType: "text",
        executeOnPressButtonGroup: `enviarMensagemWhatsApp`,
        iconAppendButton: "chat-left-text",
        buttonText: "Enviar",
        isInputMask: true,
        maskOptions: ["(##) ####-####", "(##) #####-####"],
      },
    ];
  }

  obterInputsCartaoCredito() {
    return [
      {
        label: "Nome impresso no cartao de crédito",
        id: "holderName",
        model: "holderName",
        xl: 6,
        cols: 12,
      },
      {
        label: "Número do cartao de crédito",
        id: "number",
        model: "number",
        xl: 6,
        cols: 12,
        inputType: "number",
        iconAppendButton: "credit-card-2-front",
        appendButton: true,
        isInputMask: true,
        maskOptions: ["####-####-####-####"],
      },
      {
        label: "CCV",
        id: "ccv",
        model: "ccv",
        inputType: "number",
        xl: 4,
        cols: 12,
        iconAppendButton: "credit-card",
        appendButton: true,
        isInputMask: true,
        maskOptions: ["###"],
      },
      {
        label: "Mês validade final",
        id: "expiryMonth",
        model: "expiryMonth",
        inputType: "number",
        xl: 4,
        cols: 12,
        isInputMask: true,
        maskOptions: ["##"],
      },
      {
        label: "Ano validade final",
        id: "expiryYear",
        model: "expiryYear",
        inputType: "number",
        xl: 4,
        cols: 12,
        isInputMask: true,
        maskOptions: ["####"],
      },
    ];
  }

  obterInputsPagamentoBalcao() {
    return [
      {
        label: "Confirme o valor pago",
        id: "price",
        model: "price",
        isInputCurrencyMask: true,
        xl: 12,
        cols: 12,
      },
    ];
  }

  obterInputsTipoPagamento(ids) {
    return [
      {
        label: "Selecione a forma de pagamento",
        id: "billingType",
        model: "billingType",
        isMultiselect: true,
        multiple: false,
        labelsMultiselect: "name",
        placeholder: "busque pela forma de pagamento",
        optionsAxios: {
          method: "GET",
          url: `/enumerations/billing_types?key_transform_camel_lower=true`,
          params: {
            sort_direction: "desc",
            active: true,
            profile_type: 1,
            ids: ids,
          },
        },
        axiosPathData: "data.billingTypes",
        axiosKeyFilter: "name",
      },
    ];
  }

  obterBotoesAcaoModalPagamento() {
    return [
      {
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Cancelar",
        hasConfirmation: true,
        confirmationText: "Tem certeza que deseja cancelar o pagamento?",
        variant: "light",
        click: "this.fecharModal('componente-pagamentos-global')",
      },
    ];
  }

  obterBotoesAcaoPagamentoCartao() {
    return [
      {
        iconProps: {
          icon: "cart-check",
          variant: "primary",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        classButton: "text-white",
        buttonText: "Pagar",
        variant: "success",
        click: "this.enviarDadosPagamentoCartao()",
      },
    ];
  }

  obterBotoesAcaoPagamentoBalcao() {
    return [
      {
        iconProps: {
          icon: "cart-check",
          variant: "primary",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        classButton: "text-white",
        buttonText: "Recebido",
        variant: "success",
        click: "this.enviarDadosPagamentoBalcao()",
      },
    ];
  }
  obterBotoesAcaoPagamentoPixInfinitePay() {
    return [
      {
        iconProps: {
          icon: "cart-check",
          variant: "primary",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        classButton: "text-white",
        buttonText: "Recebido",
        variant: "success",
        click: "this.enviarDadosPagamentoPixInfinitePay()",
      },
    ];
  }
}

export default new UtilsComponentePagamento();
