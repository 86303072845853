import Vue from "vue";
import NotificacaoCustomizada from "../components/NotificacaoCustomizada.vue";

// Variável para armazenar a instância do componente de notificação
let notificacaoInstance = null;

/**
 * Plugin de notificação customizada que substitui o $notify padrão
 * Mantém a mesma interface do $notify original (title, text, duration, type)
 * mas utiliza o componente CustomModal para exibir as notificações
 */
const NotificacaoPlugin = {
  install(Vue) {
    // Registra o componente globalmente
    Vue.component("NotificacaoCustomizada", NotificacaoCustomizada);

    // Função para criar a instância do componente de notificação
    const createNotificationInstance = () => {
      // Se já existe uma instância, retorna ela
      if (notificacaoInstance) {
        return notificacaoInstance;
      }

      // Cria o elemento container para a notificação
      const notificacaoContainer = document.createElement("div");
      notificacaoContainer.id = "notificacao-customizada-container";
      document.body.appendChild(notificacaoContainer);

      // Cria a instância do componente
      const NotificacaoConstructor = Vue.extend(NotificacaoCustomizada);
      notificacaoInstance = new NotificacaoConstructor();
      notificacaoInstance.$mount("#notificacao-customizada-container");

      return notificacaoInstance;
    };

    // Sobrescreve o método $notify
    Vue.prototype.$notify = function (options) {
      // Garante que a instância existe
      const instance = createNotificationInstance();

      // Chama o método show da instância com as opções fornecidas
      instance.show(options);

      // Retorna a instância para manter compatibilidade com possíveis usos do retorno
      return instance;
    };
  },
};

export default NotificacaoPlugin;
