import api from "../http/index";
import axios from "axios";

class Genericos {
  async obterDadosPorCnpj(cnpj) {
    const url = `account_admins/headquarters/consult_cnpj?key_transform_camel_lower=true`;

    let retorno = await api({
      method: "GET",
      url: `${url}`,
      params: {
        cnpj: cnpj,
      },
    });

    return retorno;
  }

  async obterDadosPorCEP(cep) {
    const url = `https://viacep.com.br/ws/${cep}/json/`;

    let retorno = await axios({
      method: "GET",
      url: `${url}`,
    });

    return retorno;
  }

  async enviarMensagemWhatsApp({ numero, mensagem }) {
    return await api({
      method: "POST",
      url: "account_admins/messages",
      data: {
        message: {
          cellphone: numero,
          message: mensagem,
        },
      },
    });
  }
}

export default new Genericos();
