class UtilsSeletores {
  obterCabecalhosTabelaListaProdutos() {
    return [
      {
        key: "file",
        sortable: true,
        label: "",
        thClass: "text-center",
        tdClass: "text-center p-3",
      },
      {
        key: "id",
        sortable: true,
        label: "id",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "uid",
        sortable: true,
        label: "uid",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "createdAt",
        sortable: true,
        label: "createdAt",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "updatedAt",
        sortable: true,
        label: "updatedAt",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "deletedAt",
        sortable: true,
        label: "deletedAt",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "code",
        sortable: true,
        label: "code",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "name",
        sortable: false,
        label: "Nome",
        thClass: "text-center",
        tdClass: "text-center p-3",
      },
      {
        key: "description",
        sortable: true,
        label: "description",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "active",
        sortable: true,
        label: "Ativo",
        thClass: "d-none",
        tdClass: "d-none p-3",
      },
      {
        key: "code",
        sortable: false,
        label: "Código",
      },
      {
        key: "suggestedPrice",
        label: "Preço",
        formatter: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
        sortable: false,
        thClass: "text-center",
        tdClass: "text-center p-3",
        // Variant applies to the whole column, including the header and footer
        // variant: 'danger'
      },
    ];
  }

  obterCabecalhosTabelaListaServicos() {
    return [
      {
        key: "id",
        sortable: true,
        label: "id",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "uid",
        sortable: true,
        label: "uid",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "createdAt",
        sortable: true,
        label: "createdAt",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "updatedAt",
        sortable: true,
        label: "updatedAt",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "deletedAt",
        sortable: true,
        label: "deletedAt",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "code",
        sortable: true,
        label: "code",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "name",
        sortable: true,
        label: "Nome",
        thClass: "text-left p-3",
        tdClass: "text-left p-3",
      },
      {
        key: "description",
        sortable: true,
        label: "Descrição",
        thClass: "text-left p-3",
        tdClass: "text-left p-3",
      },

      {
        key: "active",
        sortable: true,
        label: "Ativo",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "defaultDuration",
        sortable: true,
        label: "duration",
        thClass: "d-none",
        tdClass: "d-none",
      },
      {
        key: "suggestedPrice",
        label: "Preço",
        formatter: (value) => {
          return (
            value &&
            value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        },
        sortable: true,
        thClass: "text-left p-3",
        tdClass: "text-left p-3",
        // Variant applies to the whole column, including the header and footer
        // variant: 'danger'
      },
    ];
  }
}

export default new UtilsSeletores();
