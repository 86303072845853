<template>
  <b-row>
    <b-col
      :style="
        bgImage !== ''
          ? `background-image: url(${bgImage}); background-size: cover; background-position: center; background-repeat: no-repeat; height: 100vh;`
          : 'background-color: var(--cor-cards);'
      "
      cols="-"
      sm="-"
      md="-"
      lg="7"
      xl="8"
      class="d-none d-lg-block"
    >
    </b-col>
    <b-col
      cols="12"
      sm="12"
      md="12"
      lg="5"
      xl="4"
      style="overflow: auto !important"
      class="container-formulario-login text-center p-5"
    >
      <b-col class="mt-2" :key="componentKeyHeader" v-if="tipoLogin != 3">
        <ImageThumb
          :hasDetailsModal="false"
          :rounded="false"
          :altura="120"
          :thumbnail="false"
          :largura="300"
          :src="
            empresa.urlImagem != undefined
              ? `${empresa.urlImagem}`
              : require('../../assets/mb-solucoes.png')
          "
        />
      </b-col>

      <b-col class="mt-2 text-start" cols="12">
        <p class="text-white mb-1">
          {{ informacaoEtapaLogin }}
        </p>
      </b-col>

      <div style="overflow-x: hidden" :key="componentKeyForm">
        <CustomForm
          v-if="inputs.length > 0"
          ref="formulario"
          :opcoes="inputs"
          :dados="dadosLogin"
        />
      </div>

      <b-row class="text-center mt-4" :key="componentKeyBotoes">
        <GroupButton
          v-if="opcoesGrupoBotoes.length > 0"
          v-on:group-button-click="executarMetodoEval"
          :opcoes="opcoesGrupoBotoes"
        />
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */

import AuthService from "../../services/authentication";
import ServicosFiliais from "../../services/headquarters";
import Mixin from "../../core/Mixin";
import Utils from "./utils";
import ThemeService from "../../services/ThemeService";

export default {
  components: {},
  mixins: [Mixin],
  data: function () {
    return {
      tipoLogin: 1,
      user: [],
      inputs: [],
      empresa: {},
      bgImage: "",
      informacaoEtapaLogin: "",
      nomeEmpresa: this.$subdominio,
      componentKeyForm: 0,
      componentKeyBotoes: 120,
      componentKeyHeader: 220,
      opcoesGrupoBotoes: [],

      dadosLogin: {},
    };
  },
  async mounted() {
    this.$el.addEventListener("keydown", this.handleKeyPress);
    this.setLoading(true);

    await this.obterInformacoesSubdominio();

    this.deleteItemFromLocalStorage("dadosUsuarioLogado");

    await this.preselecionarFilialUnica();
    this.obterInputs(this.tipoLogin);
    this.obterBotoesLogin(this.tipoLogin);

    this.obterInformacaoEtapaLogin();

    this.setLoading(false);
  },
  methods: {
    async preselecionarFilialUnica() {
      let retorno = await ServicosFiliais.obterFiliais(
        this.$subdominio,
        this?.empresa?.id
      );

      console.log(retorno, "retorno");

      if (this.lodash.size(retorno.data.headquarters) == 1) {
        this.dadosLogin.filial = this.lodash.get(
          retorno,
          "data.headquarters[0]"
        );
      }
    },

    atualizarContainerFormulario() {
      this.componentKeyForm += 1;
    },

    atualizarContainerBotoes() {
      this.componentKeyBotoes += 1;
    },

    atualizarContainerHeader() {
      this.componentKeyHeader += 1;
    },

    obterBotoesLogin(modo) {
      this.opcoesGrupoBotoes = Utils.obterEstruturaBotoesLogin(modo);
    },

    selecaoEstabelecimento() {
      this.$router.push({ name: "Estabelecimentos" }).catch(() => {});
    },

    handleKeyPress(event) {
      if (event.key === "Enter") {
        this.realizarLogin(this.obterObjetoUsuarioPorTipo(1));
      }
    },

    async loginGoogle() {
      try {
        this.setLoading(true);
        let dados = { ...this.$refs.formulario.obterDadosFormulario() };

        await AuthService.loginGoogle(dados);

        console.log(dados);
      } catch (e) {
        console.log(e);
        this.setLoading(false);

        this.$notify({
          title: "Houve uma falha",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      } finally {
        this.setLoading(false);
      }
    },

    async enviarCodigoRedefinicaoSenha() {
      try {
        this.setLoading(true);

        let dados = { ...this.$refs.formulario.obterDadosFormulario() };

        await AuthService.obterCodigoRedefinicaoSenha({
          cellphone: dados.cellphone.toString(),
          subdomain: this.$subdominio,
        });

        this.setLoading(false);

        this.$notify({
          title: "Mensagem enviada",
          text: `
            <p>Verifique seu celular, um código de recuperação de senha foi enviado a você.</p>
          `,
          duration: 5000,
          type: "success",
        });

        this.mudarTipoLogin(6);
      } catch (e) {
        console.log(e);
        this.setLoading(false);

        this.$notify({
          title: "Houve uma falha",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      }
    },

    async enviarRedefinicaoSenha() {
      try {
        this.setLoading(true);

        let dados = { ...this.$refs.formulario.obterDadosFormulario() };

        let autenticacaoCodigo =
          await AuthService.validarCodigoRedefinicaoSenha({
            cellphone: dados.cellphone.toString(),
            code: dados.code.toString(),
            subdomain: this.$subdominio,
          });

        await AuthService.enviarNovaSenhaRecuperacaoSenha({
          validacao: { ...autenticacaoCodigo.headers },
          password: dados.password,
          password_confirmation: dados.passwordConfirmation,
        });

        this.mudarTipoLogin(1);

        this.setLoading(false);

        this.$notify({
          title: "Redefinição de senha",
          text: `
            <p>Sua senha foi alterada com sucesso</p>
          `,
          duration: 5000,
          type: "success",
        });
      } catch (e) {
        this.setLoading(false);
        this.$notify({
          title: "Houve uma falha",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      }
    },

    obterInformacaoEtapaLogin() {
      if (this.tipoLogin == 1) {
        this.informacaoEtapaLogin =
          "Informe seu número de celular e senha da sua conta para continuar:";
      }
      if (this.tipoLogin == 3) {
        this.informacaoEtapaLogin =
          "Informe os dados solicitados abaixo para criar uma conta:";
      }
      if (this.tipoLogin == 4) {
        this.informacaoEtapaLogin =
          "Informe seu nome completo e celular para continuar";
      }
      if (this.tipoLogin == 5) {
        this.informacaoEtapaLogin =
          "Informe seu nome número de celular vinculado a conta e lhe enviaremos uma mensagem o código para a recuperação de senha.";
      }
      if (this.tipoLogin == 6) {
        this.informacaoEtapaLogin =
          "Informe a nova senha e o código recebido em seu celular";
      }

      if (this.tipoLogin == 7) {
        this.informacaoEtapaLogin = "Faça login com sua conta Google";
      }
    },

    obterObjetoUsuarioPorTipo(modo) {
      let user = {};

      try {
        let dados = { ...this.$refs.formulario.obterDadosFormulario() };

        if (modo == 3) {
          user = {
            ...dados,
            headquarter_id: this.lodash.get(dados, "filial.id", 1),
            login: dados.cellphone ? dados.cellphone.toString() : dados.email,
          };
        } else if (modo == 4) {
          user = {
            ...dados,
            headquarter_id: this.lodash.get(dados, "filial.id", 1),
            login: dados.cellphone.toString(),
            password: dados.name,
            passwordConfirmation: dados.name,
          };
        } else {
          user = {
            ...dados,
            login: dados.login.toString(),
          };
        }

        return { ...user };
      } catch (e) {
        this.setLoading(false);

        this.$notify({
          title: "Houve uma falha",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      }
    },

    async criarConta(modo = 0) {
      try {
        this.setLoading(true);

        // await AuthService.criarConta({
        //   empresa: this.$subdominio,
        //   user: { ...this.obterObjetoUsuarioPorTipo(this.tipoLogin) },
        // });

        // this.realizarLogin(this.obterObjetoUsuarioPorTipo(this.tipoLogin));

        this.dadosLogin = {
          login: this.lodash.get(this.obterObjetoUsuarioPorTipo(3), "login"),
        };
        this.mudarTipoLogin(1);

        this.$notify({
          title: "Conta criada",
          text: `
            <p>
              Sua conta foi criada com sucesso, verifique seus dados de acesso no WhatsApp ${this.lodash.get(
                this.dadosLogin,
                "login"
              )}.
            </p>
          `,
          duration: 5000,
          type: "success",
        });

        this.setLoading(false);
      } catch (e) {
        if (e.response && e.response.status == 422) {
          this.realizarLogin(this.obterObjetoUsuarioPorTipo(this.tipoLogin));
        }

        this.setLoading(false);

        console.error(e);
      }
    },

    async obterInformacoesSubdominio() {
      try {
        let response = await AuthService.obterInformacoesSubdominio({
          empresa: this.$subdominio,
        });

        console.log(response);
        this.deleteItemFromLocalStorage("imagensEmpresa");
        this.deleteItemFromLocalStorage("coresAmbiente");

        this.empresa = {
          urlImagem: this.lodash.get(
            response,
            "data.account.logo.larger",
            undefined
          ),
          ...response.data.account,
        };

        this.bgImage = this.lodash.get(
          response,
          "data.account.background.url",
          ""
        );

        this.addItemToLocalStorage(`coresAmbiente`, {
          primary: this.lodash.get(
            response,
            "data.account.color_configs.primaryColor",
            "#1ed760"
          ),
        });
        ThemeService.setPrimaryColor(
          this.lodash.get(
            response,
            "data.account.color_configs.primaryColor",
            "#1ed760"
          )
        );

        this.addItemToLocalStorage(`imagensEmpresa`, {
          background: this.lodash.get(
            response,
            "data.account.background.larger",
            ""
          ),
          logo: this.lodash.get(response, "data.account.logo.url", undefined),
        });

        this.nomeEmpresa = this.lodash.get(
          response,
          "data.account.name",
          "Skedoole"
        );

        document.title = this.nomeEmpresa;

        this.atualizarContainerHeader();
      } catch (e) {
        this.$notify({
          title: "Houve uma falha",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
        this.selecaoEstabelecimento();
      }
    },

    mudarTipoLogin(novoModo) {
      this.setLoading(true);

      this.tipoLogin = novoModo;
      this.users = [];

      this.obterInputs(novoModo);
      this.obterBotoesLogin(novoModo);
      this.obterInformacaoEtapaLogin();

      this.atualizarContainerFormulario();
      this.atualizarContainerBotoes();

      this.setLoading(false);
    },

    obterInputs(modo) {
      let ehMultiFilial = !this.lodash.get(this.dadosLogin, "filial.id", false)
        ? true
        : false;

      this.inputs = Utils.obterEstruturaInputsLogin(
        modo,
        this.$subdominio,
        this?.empresa?.id,
        ehMultiFilial
      );
    },

    async realizarLogin(dados) {
      try {
        console.log(dados, "dados");
        this.setLoading(true);
        let formulario = {
          ...dados,
          subdomain: this.$subdominio,
        };
        console.log(formulario, "formulario");
        if (!formulario || !formulario.filial || !formulario.filial.id) {
          throw "Selecione a filial";
        }

        let autenticacao = await AuthService.enviarAutenticacao(formulario);

        if (!autenticacao || !autenticacao.isAuth) {
          throw "Usuário ou senha inválidos, verifique os dados informados";
        }

        this.aplicarDadosUsuarioLogado(autenticacao);

        let isAdmin = this.lodash.get(autenticacao, "user.isAdmin", false);
        if (isAdmin) {
          this.$router.push({ name: "Painel interno Skedoole" });
        } else {
          this.$router.push({ name: "Home" });
        }
      } catch (e) {
        console.log(e, "???");
        this.$notify({
          title: "Houve uma falha",
          text: `${this.lodash.get(e, "response.data.errors.base", e)}`,
          duration: 5000,
          type: "error",
        });
      } finally {
        this.setLoading(false);
      }
    },

    aplicarDadosUsuarioLogado(dados) {
      if (!dados.user.isAdmin) {
        delete dados.user;
      }

      let dadosFormulario = { ...this.$refs.formulario.obterDadosFormulario() };

      this.addItemToLocalStorage("dadosUsuarioLogado", {
        ...dados,
        subdomain: this.$subdominio,
        nomeEmpresa: this.nomeEmpresa,
        filial: dadosFormulario.filial,
      });
    },
  },

  beforeDestroy() {
    // Certifique-se de remover o ouvinte de eventos ao destruir o componente
    this.$el.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style lang="scss">
.card-body {
  height: 100vh !important;
}

.fundo-login {
  background-color: var(--cor-cards);
  background-size: cover;
}

button {
  white-space: pre;
}
</style>
