class ThemeService {
  constructor() {
    this.defaultTheme = {
      //   primary: "#ffff00",
      primary: "#1db954",
      hover: "#1ed760",
      gradientStart: "#1db954",
      gradientEnd: "#20c65a",
    };
    this.currentTheme = { ...this.defaultTheme };
  }

  setThemeColor(color) {
    document.documentElement.style.setProperty("--cor-primaria", color);
    document.documentElement.style.setProperty(
      "--cor-hover",
      this.adjustBrightness(color, 10)
    );
    document.documentElement.style.setProperty("--cor-gradient-start", color);
    document.documentElement.style.setProperty(
      "--cor-gradient-end",
      this.adjustBrightness(color, 5)
    );

    // Update Bootstrap primary color
    document.documentElement.style.setProperty("--primary", color);
    document.documentElement.style.setProperty("--bs-primary", color);

    // Convert hex to RGB for Bootstrap link color
    const rgb = this.hexToRgb(color);
    document.documentElement.style.setProperty(
      "--bs-link-color-rgb",
      `${rgb.r}, ${rgb.g}, ${rgb.b}`
    );

    // Update additional UI elements
    const style = document.createElement("style");
    style.textContent = `
      .btn-primary { background-color: ${color} !important; border-color: ${color} !important; }
      .v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
      .v-sidebar-menu .vsm--link_level-1.vsm--link_active { color: ${color} !important; box-shadow: 3px 0px 0px 0px ${color} inset; }
      .vsm--link_level-1 { color: ${color} !important; }
      .vsm--title { color: white !important; }
    `;
    document.head.appendChild(style);
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  adjustBrightness(hex, percent) {
    hex = hex.replace(/^#/, "");
    const num = parseInt(hex, 16);
    const amt = Math.round(2.55 * percent);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;
    return `#${(
      (1 << 24) |
      ((R < 255 ? (R < 1 ? 0 : R) : 255) << 16) |
      ((G < 255 ? (G < 1 ? 0 : G) : 255) << 8) |
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)}`;
  }

  resetToDefault() {
    this.setThemeColor(this.defaultTheme.primary);
  }

  setPrimaryColor(color) {
    if (color) {
      this.currentTheme.primary = color;
      this.setThemeColor(color);
    } else {
      this.resetToDefault();
    }
    return this.currentTheme.primary;
  }
}

export default new ThemeService();
