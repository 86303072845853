<template>
  <div :key="componentKey">
    <vue-navigation-bar
      :options="navbarOptions"
      @vnb-item-clicked="changeRoute"
      :class="!sidebarOpened ? 'sidebar-opened' : ''"
    >
      <template #custom-section>
        <div style="display: contents" v-if="isMobile()">
          <CustomButton
            :iconProps="{
              icon: 'list',
              variant: 'light',
              'font-scale': '1.7',
            }"
            :containerProps="{
              cols: '2',
            }"
            buttonText=""
            variant="outline-light"
            classButton="text-white border-0 ml-3 text-left p-1"
            @button-click="onToggleCollapse(!sidebarOpened)"
          />
        </div>
        <b-col cols="4">
          <ImageThumb
            :hasDetailsModal="false"
            :rounded="false"
            :altura="42"
            :thumbnail="false"
            :errorSrc="require('../assets/mb-solucoes.png')"
            :src="
              lodash.get(
                $dadosSessao,
                'user.account.logo',
                require('../assets/mb-solucoes.png')
              )
            "
          />
        </b-col>
      </template>
    </vue-navigation-bar>

    <sidebar-menu
      v-if="exibeMenuLateral"
      ref="menu-lateral"
      :collapsed="true"
      :menu="menu"
      :widthCollapsed="!this.isMobile() ? '60px' : '49px'"
      :hideToggle="false"
      @toggle-collapse="onToggleCollapse"
      @item-click="onToggleCollapse(true)"
      :rtl="false"
      theme="dark-theme"
    >
      <span style="font-size: 1.5em" slot="toggle-icon">
        <i v-if="!sidebarOpened" class="fa-solid fa-toggle-on mr-3"></i>
        <i v-if="sidebarOpened" class="fa-solid fa-toggle-off mr-3"></i>
      </span>

      <div v-if="!sidebarOpened" slot="footer" class="text-center">
        <p class="service-details" style="font-size: 0.9em">
          Desenvolvido por <b>Skedoole</b>
        </p>
      </div>
    </sidebar-menu>
  </div>
</template>
  
  <script>
import Mixin from "../core/Mixin";

export default {
  mixins: [Mixin],
  name: "Navbar",

  data: function () {
    return {
      componentKey: 0,
      exibeMenuLateral: !this.isMobile(),
      sidebarOpened: true,
      menu: [],
      nomeUsuario: "",
      imagens: {},
      deferredPrompt: null,
      navbarOptions: {
        elementId: "main-navbar",
        class: "nk-header is-light",
        isUsingVueRouter: true,
        mobileBreakpoint: 50,
        collapseButtonOpenColor: "black",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: false,
        ariaLabelMainNav: "Skedoole",
        tooltipAnimationType: "perspective",
        tooltipPlacement: "bottom",
        menuOptionsLeft: [],
        menuOptionsRight: [],
      },
    };
  },

  async mounted() {
    this.obterImagens();
    this.montarImagensEmpresa();
    this.obterNomeUsuarioLogado();
    this.obterMenusPorTipoUsuario();
    this.obterTopBarOptions();

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;

      // Only show install option if PWA is not installed
      if (this.deferredPrompt) {
        const installOption =
          this.navbarOptions.menuOptionsRight[0].subMenuOptions.find(
            (option) => option.text === "Instalar aplicativo"
          );
        if (installOption) {
          installOption.hidden = false;
        }
      }
    });

    // Add check for installed state
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
      const installOption =
        this.navbarOptions.menuOptionsRight[0].subMenuOptions.find(
          (option) => option.text === "Instalar aplicativo"
        );
      if (installOption) {
        installOption.hidden = true;
      }
    });

    await this.$nextTick();
    this.updateUserToggleStyle();
  },

  methods: {
    async installPWA() {
      try {
        if (!this.deferredPrompt) {
          // Check if running in standalone mode
          if (
            window.matchMedia("(display-mode: standalone)").matches ||
            window.navigator.standalone === true
          ) {
            this.$notify({
              group: "custom-notify",
              type: "warn",
              title: "Instalação do PWA",
              text: "O aplicativo já está instalado.",
            });
          } else {
            this.$notify({
              group: "custom-notify",
              type: "warn",
              title: "Instalação do PWA",
              text: "O aplicativo não pode ser instalado neste momento. Verifique se seu navegador suporta PWA.",
            });
          }
          return;
        }

        await this.deferredPrompt.prompt();
        const choiceResult = await this.deferredPrompt.userChoice;

        if (choiceResult.outcome === "accepted") {
          this.$notify({
            group: "custom-notify",
            type: "success",
            title: "Instalação do PWA",
            text: "Aplicativo instalado com sucesso!",
          });
          // Hide install option after successful installation
          const installOption =
            this.navbarOptions.menuOptionsRight[0].subMenuOptions.find(
              (option) => option.text === "Instalar aplicativo"
            );
          if (installOption) {
            installOption.hidden = true;
          }
        } else {
          this.$notify({
            group: "custom-notify",
            type: "info",
            title: "Instalação do PWA",
            text: "Instalação cancelada pelo usuário.",
          });
        }

        this.deferredPrompt = null;
      } catch (error) {
        console.error("Error installing PWA:", error);
        this.$notify({
          group: "custom-notify",
          type: "error",
          title: "Erro",
          text: "Ocorreu um erro ao tentar instalar o aplicativo.",
        });
      }
    },
    updateUserToggleStyle() {
      let foto = this.lodash.get(this.$dadosSessao, "user.photo.small", "");

      if (foto != "") {
        const userToggle = document.querySelector(".user-avatar");
        userToggle.style.backgroundImage = `url(${foto})`;
        userToggle.style.backgroundSize = "cover";
        userToggle.style.backgroundPosition = "center";

        const userToggleIcon = document.querySelector(".ni-user-alt");
        userToggleIcon.style.color = "transparent";
      }
    },

    obterTopBarOptions() {
      this.navbarOptions.menuOptionsRight = [
        {
          type: "link",
          text: "",
          iconRight: `
              <li class="dropdown user-dropdown">
                <a href="#" class="dropdown-toggle me-n1" data-bs-toggle="dropdown">
                  <div class="user-toggle">
                    <div style="background-color:var(--cor-primaria)" class="user-avatar sm"><em class="icon ni ni-user-alt"></em></div>
                    <div class="user-info d-none d-xl-block">
                      <div class="user-name">${this.lodash.get(
                        this,
                        "$dadosSessao.user.name",
                        ""
                      )}</div>
                    </div>
                  </div>
                </a>
              </li>
            `,
          subMenuOptions: [
            // {
            //   isLinkAction: true,
            //   type: "link",
            //   text: "Instalar aplicativo",
            //   path: "#",
            //   iconLeft: '<em class="icon ni ni-download"></em>',
            //   hidden: true,
            //   click: () => this.installPWA(),
            // },
            {
              type: "hr",
            },
            {
              isLinkAction: true,
              type: "link",
              text: "Meu Perfil",
              path: { name: "" },
              iconLeft:
                '<li><a href="/demo2/lms/admin-profile.html"><em class="icon ni ni-user-alt"></em><span></span></a></li>',
            },
            {
              type: "hr",
            },
            {
              isLinkAction: true,
              type: "link",
              text: "Planos",
              path: { name: "Listagem planos" },
              iconLeft:
                '<li><a href="/demo2/lms/admin-profile.html"><em class="icon ni ni-reports"></em><span></span></a></li>',
            },
            {
              type: "hr",
            },
            {
              isLinkAction: true,
              type: "link",
              text: "Sair",
              path: { name: "Login" },
              iconLeft:
                '<li><a href="#"><em class="icon ni ni-signout"></em></a></li>',
            },
          ],
        },
      ];
    },

    onToggleCollapse(collapsed) {
      if (this.isMobile() && !this.exibeMenuLateral) {
        this.exibeMenuLateral = !collapsed;
      }
      this.$nextTick(() => {
        this.sidebarOpened = collapsed;
        this.$refs["menu-lateral"].isCollapsed = collapsed;
        this.$emit("sidebar-toggle", !collapsed);

        if (this.isMobile() && this.exibeMenuLateral) {
          this.exibeMenuLateral = !collapsed;
        }
      });
    },

    obterNomeUsuarioLogado() {
      this.nomeUsuario = this.getItemsFromLocalStorage("dadosUsuarioLogado")[0];
    },

    obterImagens() {
      this.imagens = this.getItemsFromLocalStorage("imagensEmpresa")[0];
    },

    montarImagensEmpresa() {
      this.navbarOptions.brandImage = this.imagens?.logo ?? "";
    },

    changeRoute(route) {
      if (route == "Instalar aplicativo") {
        this.installPWA();
      }
      if (route == "Planos") {
        this.$router
          .push({
            name: "Listagem planos",
            params: {
              id: this.lodash.get(this, "$dadosSessao.user.id", ""),
            },
          })
          .catch((e) => {
            console.error(e);
          });
      }

      if (route == "Meu Perfil" && this.$route.name != "Cadastro Usuario") {
        this.$router
          .push({
            name: "Cadastro Usuario",
            params: {
              id: this.lodash.get(this, "$dadosSessao.user.id", ""),
            },
          })
          .catch((e) => {
            console.error(e);
          });
      }

      if (route == "Sair") {
        this.$router
          .push({
            path: `/login`,
          })
          .catch(() => {
            window.location.assign(
              `https://app.skedoole.com.br/estabelecimentos`
            );
          });
      }
    },

    obterMenusPorTipoUsuario() {
      let itensNavegacao = this.lodash.get(
        this,
        "$dadosSessao.user.homeTools",
        []
      );

      this.menu = [
        {
          header: "Main Navigation",
          hiddenOnCollapse: false,
        },
        {
          href: "/home",
          title: "Inicio",
          icon: {
            element: "i",
            class: "fa-sharp fa-solid fa-house",
          },
        },
      ];

      itensNavegacao.map((item) => {
        this.menu.push({
          href: item.slug,
          title: item.name,
          icon: item.icon,
        });
      });

      this.menu.push({
        href: `/login`,
        title: "Sair",
        icon: {
          element: "i",
          class: "fa-solid fa-arrow-right-from-bracket",
        },
      });
    },
  },

  watch: {
    windowWidth: {
      immediate: true,
      handler() {
        this.componentKey += 1;
        this.exibeMenuLateral = !this.isMobile();
      },
    },
  },
};
</script>
  
  <style lang="scss">
#main-navbar {
  margin-left: 0px;
  display: flex;
  justify-content: end;
  transition: margin-left 0.8s ease;
}

@media (min-width: 992px) {
  #main-navbar.sidebar-opened {
    margin-left: 15vw !important;
  }
}
</style>
  
  