<template>
  <b-col :class="containerClass" :key="componentKey">
    <b-icon
      :style="containerStyle"
      @click="hintPressionado"
      :id="idGerado"
      :icon="nomeIcone"
      :variant="variantIcone"
      :font-scale="tamanhoIcone"
    />
    <b-popover
      v-if="!isMobile()"
      :target="idGerado"
      triggers="hover"
      placement="top"
      custom-class="dark-background"
    >
      <template #title>
        <b class="fonte-customizada-dark">{{ titulo }}</b></template
      >
      <slot name="texto"></slot>

      <b v-if="texto != ''" class="text-center">{{ texto }}</b>
    </b-popover>

    <b-modal
      id="modal-detalhes-hint"
      ref="modal-detalhes-hint"
      size="lg"
      modal-class="custom-modal"
      centered
      hide-header
      :title="titulo"
      header-bg-variant="dark"
      body-bg-variant="dark"
      footer-bg-variant="dark"
    >
      <div class="m-3" v-html="hintText"></div>

      <template #modal-footer>
        <CustomButton
          buttonText="Fechar"
          variant="light"
          @button-click="fecharModal"
        />
      </template>
    </b-modal>
  </b-col>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  name: "GroupButton",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      titulo: "",
      texto: "",
      nomeIcone: "",
      tamanhoIcone: 1,
      variantIcone: "light",
      componentKey: 1,
      idGerado: new Date().getTime().toString(),
    };
  },
  props: {
    hintText: {
      type: String,
      default: "",
    },
    hintTitle: {
      type: String,
      default: "Informação",
    },
    iconName: {
      type: String,
      default: "",
    },
    fontSize: {
      type: Number,
      default: 1,
    },
    variant: {
      type: String,
      default: "",
    },
    containerStyle: {
      type: String,
      default: "",
    },
    containerClass: {
      type: String,
      default: "",
    },
    showModal: {
      type: Boolean,
      default: true,
    },
  },

  mounted() {
    this.montarEstrutura();
  },

  methods: {
    hintPressionado() {
      if (this.showModal) {
        this.exibirModal();
      }
    },

    atualizarComponente() {
      this.montarEstrutura();
      this.componentKey += 1;
    },

    montarEstrutura() {
      this.titulo = this.hintTitle;
      this.texto = this.hintText;
      this.nomeIcone = this.iconName;
      this.tamanhoIcone = this.fontSize;
      this.variantIcone = this.variant;
    },

    exibirModal(nomeModal = "modal-detalhes-hint") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].show();
      }
    },

    fecharModal(nomeModal = "modal-detalhes-hint") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].hide();
      }
    },
  },
};
</script>
<style>
</style>
