<template>
  <Transition :name="transitionName">
    <b-col
      :sm="colSM"
      :md="colMD"
      :lg="colLG"
      :xl="colXL"
      v-if="showMyDiv"
      :class="`container-card-custom ${containerSlotClass}`"
    >
      <h4 class="pl-0 card-title fonte-customizada">{{ titulo }}</h4>
      <h6 class="card-subtitle mb-3">{{ subtitulo }}</h6>
      <div class="teste">
        <slot></slot>
      </div>
    </b-col>
  </Transition>
</template>


<script>
import Mixin from "../core/Mixin";

export default {
  mixins: [Mixin],
  name: "Card",
  data() {
    return {
      showMyDiv: false,
      cardHeight: "",
    };
  },
  props: {
    titulo: {
      type: String,
      // required: true,
    },
    subtitulo: {
      type: String,
      // required: true,
    },
    colSM: {
      type: String,
      required: false,
      default: "12",
    },
    colMD: {
      type: String,
      required: false,
      default: "12",
    },
    colLG: {
      type: String,
      required: false,
      default: "12",
    },
    colXL: {
      type: String,
      required: false,
      default: "12",
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    logoUrl: {
      type: String,
      default: "",
    },
    containerSlotClass: {
      type: String,
      default: "",
    },
    transitionName: {
      type: String,
      required: false,
      default: "fade",
    },
  },

  mounted() {
    this.showMyDiv = true;
  },

  methods: {},
};
</script>

<style>
.container-card-custom {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  height: calc(100vh - 60px) !important;
  /* margin-bottom: 10vh !important; */
  padding-top: 0px !important;
  padding-bottom: 10vh !important;
  -webkit-overflow-scrolling: touch;
}

.card-body {
  background-color: var(--cor-fundo);
}

.card-img {
  padding-left: 2vh;
  padding-right: 2vh;
  width: 20px;
  height: 60px;
}

.card {
  border-radius: 0 !important;
  border: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}

.slide-right-enter {
  transform: translateX(100%);
}

.slide-right-leave-to {
  transform: translateX(-100%);
}

@media (max-width: 768px) {
  .container-card-custom {
    height: calc(100vh - 140px) !important;
    max-height: 100%;
    position: relative;
    padding-bottom: 20vh !important;
  }
}
@media (min-width: 991px) {
  .teste {
    margin-left: 2vw;
    margin-right: 2vw;
  }
}
</style>
