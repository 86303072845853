<template>
  <b-row
    @click="imagemSelecionada"
    :key="componentKey"
    :class="`${rowContainerClass}`"
  >
    <b-col :sm="sm" :md="md" :lg="lg" :xl="xl" :cols="cols">
      <b-img
        class="cursor-pointer"
        :rounded="rounded ? 'circle' : false"
        :thumbnail="thumbnail"
        :src="
          srcImagem && srcImagem != null
            ? srcImagem
            : require('../assets/sem_imagem.png')
        "
        :width="largura"
        :height="altura"
        @error="handleImageError"
      >
      </b-img>

      <Hint
        hintTitle="Detalhes"
        :fontSize="2"
        iconName=""
        variant="light"
        containerClass="position-absolute top-0 end-0 m-2"
        :showModal="false"
      >
        <template #texto>
          <div class="image-details-container">
            <b-col class="text-center mb-3">
              <b-img-lazy
                :rounded="'circle'"
                :src="
                  srcImagem && srcImagem != null
                    ? srcImagem
                    : require('../assets/sem_imagem.png')
                "
                :blank="true"
              >
              </b-img-lazy>
            </b-col>
            <div v-if="detailsData" class="details-list">
              <div
                v-for="(value, key) in detailsData"
                :key="key"
                class="detail-item"
              >
                <strong class="detail-label text-capitalize">{{ key }}</strong>
                <span class="detail-value">{{ value }}</span>
              </div>
            </div>
          </div>
        </template>
      </Hint>
    </b-col>

    <b-modal
      id="modal-detalhes-imagem"
      ref="modal-detalhes-imagem"
      size="lg"
      modal-class="custom-modal"
      centered
      scrollable
      hide-header
      title="Detalhes imagem"
      body-class="container-detalhes-modal-options"
      footer-class="container-detalhes-modal-options"
      header-class="container-detalhes-modal-options"
    >
      <b-col class="text-center mt-3">
        <b-img-lazy
          :src="
            srcImagem && srcImagem != null
              ? srcImagem
              : require('../assets/sem_imagem.png')
          "
          :blank="true"
        >
        </b-img-lazy>
      </b-col>

      <template #modal-footer>
        <CustomButton
          buttonText="Fechar"
          variant="light"
          @button-click="fecharModal"
        />
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import Mixin from "../core/Mixin";

export default {
  name: "GroupButton",
  mixins: [Mixin],
  components: {},
  data() {
    return {
      srcImagem: "",
      componentKey: 1,
    };
  },
  props: {
    detailsData: {
      type: Object,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    thumbnail: {
      type: Boolean,
      default: true,
    },
    hasDetailsModal: {
      type: Boolean,
      default: true,
    },
    src: {
      type: String,
    },
    errorSrc: {
      type: String,
      default: require("../assets/sem_imagem.png"),
    },
    rowContainerClass: {
      type: String,
    },
    altura: {
      type: Number,
      default: 135,
    },
    largura: {
      type: Number,
      default: 135,
    },
    cols: {
      type: Number,
      default: 12,
    },
    sm: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
    lg: {
      type: Number,
      default: 12,
    },
    xl: {
      type: Number,
      default: 12,
    },
  },

  mounted() {
    this.montarEstrutura();
  },

  methods: {
    handleImageError(event) {
      event.target.src = this.errorSrc;
    },

    imagemSelecionada() {
      if (this.hasDetailsModal) {
        this.exibirModal();
      }

      this.$emit("image-selected", this.src);
    },

    atualizarComponente() {
      this.componentKey += 1;
    },

    montarEstrutura() {
      this.srcImagem = this.src;
    },

    exibirModal(nomeModal = "modal-detalhes-imagem") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].show();
      }
    },

    fecharModal(nomeModal = "modal-detalhes-imagem") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal].hide();
      }
    },
  },
};
</script>
<style scoped>
.image-details-container {
  padding: 10px;
}

.preview-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.details-list {
  margin-top: 15px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.detail-item:last-child {
  border-bottom: none;
}

.detail-label {
  color: #b3b3b3;
  margin-right: 10px;
}

.detail-value {
  color: white;
  word-break: break-word;
}
</style>
