<template>
  <div
    id="Skedoole"
    style="overflow: hidden !important; height: 100vh !important"
  >
    <Pagamento />

    <Navbar
      v-if="$route && $route.meta.layoutCompleto && $dadosSessao != undefined"
      @sidebar-toggle="updateSidebarState"
      ref="navbar"
    />

    <div
      :class="
        $route && $route.meta.layoutCompleto
          ? `${!isMobile() ? (sidebarOpened ? '' : 'ml-5') : ''} pl-4 ${
              sidebarOpened ? 'sidebar-opened' : ''
            } breadcrumb-container`
          : 'pl-4'
      "
    >
      <b-breadcrumb
        v-if="
          $route &&
          $route.meta.layoutCompleto &&
          !$route.meta.layoutAdmin &&
          $dadosSessao != undefined
        "
        :items="items"
      ></b-breadcrumb>
    </div>

    <router-view
      :class="
        $route && $route.meta.layoutCompleto
          ? `${
              !isMobile() ? (sidebarOpened ? '' : 'ml-5') : ''
            } pl-2 text-center container-navbar-router-view ${
              sidebarOpened ? 'sidebar-opened' : ''
            }`
          : 'pl-2 container-vazio-router-view'
      "
    >
    </router-view>
  </div>
</template>


<script>
import Vue from "vue";
import Mixin from "./core/Mixin";
import { mapGetters, mapActions } from "vuex";
import ThemeService from "./services/ThemeService";

export default {
  mixins: [Mixin],
  data: function () {
    return {
      alturaRouterView: "80vh",
      alturaComandaAberta: "20vh",
      exibirContainerComanda: true,
      comandaAberta: {},
      iconComandaAberta: "arrows-angle-contract",
      componentKeyComanda: 0,
      exibirBotaoComanda: false,
      items: this.$routeHistory,
      sidebarOpened: false,
    };
  },

  async created() {
    if (this.$route.query && this.$route.query.empresa) {
      Vue.prototype.$subdominio = this.$route.query.empresa;
    } else {
      Vue.prototype.$subdominio = Vue.prototype.$subdominio
        ? Vue.prototype.$subdominio
        : "Skedoole";
    }
  },

  mounted() {
    ThemeService.resetToDefault();
    ThemeService.setPrimaryColor(
      this.getItemsFromLocalStorage("coresAmbiente")?.[0]?.primary
    );
  },

  methods: {
    ...mapActions(["setLoading"]),

    updateSidebarState(collapsed) {
      this.sidebarOpened = collapsed;
    },

    obterRotaComComanda(route) {
      let rotasComComanda = ["Selecao dia mes", "Servicos", "Selecao Hora"];

      if (rotasComComanda.includes(route)) {
        this.exibirBotaoComanda = true;
        return true;
      } else {
        this.exibirBotaoComanda = false;

        return false;
      }
    },

    async exibirComanda(aberto) {
      this.comandaAberta = await this.$getStore("comanda");
    },
  },

  async updated() {
    this.componentKeyComanda = +1;
    this.comandaAberta = await this.$getStore("comanda");

    this.obterRotaComComanda(this.$route.name);
    this.items = this.$routeHistory;
  },

  computed: {
    ...mapGetters(["isLoading"]),
  },

  errorCaptured() {
    this.setLoading(false);
    // this.$router.push({ path: `home` }).catch(() => {});

    this.$notify({
      title: "Ops... Ocorreu um problema",
      text: `Houve uma falha não tratada, tente novamente`,
      duration: 5000,
      type: "error",
    });
  },
};
</script>


<style lang="scss">
#app {
  //font-family: Verdana, Geneva, sans-serif;
  color: #282a35;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // background-color: #f2f2f2 !important;
}

.container-route-name {
  // color: #f2f2f2 !important;
  background-color: rgb(0, 96, 177);
}

.route-name {
  font-weight: 540;
}

.notification-content {
  font-size: 15px;
  font-weight: 400 !important;
  background-color: var(--cor-cards);
  padding-top: 18px;
}

.notification-title {
  font-weight: 700 !important;
  font-size: 17px;

  white-space: nowrap;
  background-color: var(--cor-cards);
}

.vue-notification.success {
  padding: 15px;
  background: var(--cor-cards) !important;
  border-left: 20px solid #42a85f;
}

.vue-notification.warn {
  padding: 15px;
  background: var(--cor-cards) !important;
  border-left: 20px solid #f48a06;
}

.vue-notification.error {
  padding: 15px;
  background: var(--cor-cards) !important;
  border-left: 20px solid #b82e24;
}

@media (min-width: 992px) {
  .container-navbar-router-view {
    transition: margin-left 0.8s ease;
  }

  .container-navbar-router-view.sidebar-opened {
    margin-left: 18vw !important;
    z-index: 999;
  }

  .breadcrumb-container {
    transition: margin-left 0.8s ease;
  }

  .sidebar-opened {
    margin-left: 18vw !important;
  }
}
</style>
