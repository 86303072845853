var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow":"hidden !important","height":"100vh !important"},attrs:{"id":"Skedoole"}},[_c('Pagamento'),(_vm.$route && _vm.$route.meta.layoutCompleto && _vm.$dadosSessao != undefined)?_c('Navbar',{ref:"navbar",on:{"sidebar-toggle":_vm.updateSidebarState}}):_vm._e(),_c('div',{class:_vm.$route && _vm.$route.meta.layoutCompleto
        ? `${!_vm.isMobile() ? (_vm.sidebarOpened ? '' : 'ml-5') : ''} pl-4 ${
            _vm.sidebarOpened ? 'sidebar-opened' : ''
          } breadcrumb-container`
        : 'pl-4'},[(
        _vm.$route &&
        _vm.$route.meta.layoutCompleto &&
        !_vm.$route.meta.layoutAdmin &&
        _vm.$dadosSessao != undefined
      )?_c('b-breadcrumb',{attrs:{"items":_vm.items}}):_vm._e()],1),_c('router-view',{class:_vm.$route && _vm.$route.meta.layoutCompleto
        ? `${
            !_vm.isMobile() ? (_vm.sidebarOpened ? '' : 'ml-5') : ''
          } pl-2 text-center container-navbar-router-view ${
            _vm.sidebarOpened ? 'sidebar-opened' : ''
          }`
        : 'pl-2 container-vazio-router-view'})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }