import { mapActions } from "vuex";

const mixin = {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },

  created() {
    // Adiciona o listener de redimensionamento quando o componente é criado
    window.addEventListener("resize", this.handleResize);
  },

  destroyed() {
    // Remove o listener quando o componente é destruído para evitar memory leaks
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions(["setPropriedades"]),

    irParaRota(rota) {
      this.$router
        .push({
          name: rota,
        })
        .catch((e) => {
          console.error(e);
        });
    },

    obterCorSituacaoAgendamento(item) {
      if (item.id == 1) {
        return "info"; //Confirmado
      }
      if (item.id == 5) {
        return "success";
      }
      if (item.id == 4) {
        return "danger";
      }
      if (item.id == 3) {
        return "warning";
      }
    },

    executarMetodoEval(metodo, inputValue) {
      if (inputValue) {
        this.executarMetodoEvalComParametros(metodo, inputValue);

        return;
      }

      eval(metodo);
    },

    executarMetodoEvalComParametros(metodo, value) {
      const funcaoComParams = (param) => this[metodo](param);

      funcaoComParams(value);
    },

    obterCorSituacao(item) {
      if (!item) {
        return "danger";
      }
      if (item) {
        return "success";
      }
    },

    generateRandomNumber(index) {
      // Define a semente com base no índice
      const seed = index % 100; // Garante que a semente esteja entre 0 e 99
      const randomNumber = Math.floor((Math.sin(seed) + 1) * 50); // Gera um número entre 0 e 100
      return randomNumber;
    },

    debounce(wait) {
      return new Promise((resolve) => {
        if (this.debounceTimer) {
          clearTimeout(this.debounceTimer);
        }

        this.debounceTimer = setTimeout(() => {
          resolve(true); // Após o tempo ocioso, resolve a promessa
        }, wait);
      });
    },

    adicionarZeroAEsquerda(numeroStr) {
      // Converte a string para um número
      const numero = parseInt(numeroStr, 10);

      // Verifica se o número é menor que 9
      if (numero < 9) {
        // Adiciona zero à esquerda
        return "0" + numero;
      } else {
        return numeroStr;
      }
    },

    //Atalhos localstorage
    addItemToLocalStorage(key, item) {
      let items = this.getItemsFromLocalStorage(key);
      items.push(item);
      localStorage.setItem(key, JSON.stringify(items));
    },

    getItemsFromLocalStorage(key) {
      let items = localStorage.getItem(key);
      return items ? JSON.parse(items) : [];
    },

    deleteItemFromLocalStorage(key) {
      localStorage.removeItem(key);
    },
    //

    obterTipoPerfilUsuarioLogado() {
      let dadosUserLogado = this.$dadosSessao;

      if (this.lodash.get(dadosUserLogado, "user.profileType.id", 0) == 1) {
        //funcionario

        if (this.lodash.get(dadosUserLogado, "user.isAccountAdmin", false)) {
          return "ADMIN";
        }

        return "FUNCIONARIO";
      }

      return "CLIENTE";
    },

    obterRotaPorTipoUsuario(rotaOriginal) {
      // if(this.obterTipoPerfilUsuarioLogado() != "ADMIN"){
      //     return `/homepages/${rotaOriginal}`; // Caso precise manipular rota por tipo de usuario
      // };
      return rotaOriginal;
    },

    // Método que atualiza a largura da janela
    handleResize() {
      this.windowWidth = window.innerWidth;
    },

    // Método isMobile atualizado para usar a largura da janela do data
    isMobile() {
      return this.windowWidth <= 768;
    },

    // Funcoes auxiliares timestamp
    arredondarTimestampParaBaixo(timestamp, duration) {
      const dt = new Date(timestamp);

      // Arredondar para baixo para o intervalo de 30 minutos
      const minutes = dt.getMinutes();
      const roundedMinutes = Math.floor(minutes / duration) * duration;
      dt.setMinutes(roundedMinutes, 0, 0);

      // Obter o timestamp arredondado em milissegundos
      const roundedTimestamp = dt.getTime();

      return roundedTimestamp;
    },
    humanizarTimestamp(timestamp) {
      const data = new Date(timestamp);

      const opções = {
        day: "numeric",
        month: "long",
        year: "numeric",
      };

      const dataFormatada = data.toLocaleDateString("pt-BR", opções);
      const horaFormatada = data.toLocaleTimeString("pt-BR", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });

      return `${dataFormatada}, às ${horaFormatada}`;
    },

    // Atalhos Vuex
    $setStoreServicosOferecidos(data) {
      this.setPropriedades({
        servicosAgendados: { ...data },
      });
    },

    $setStoreServicosAgendados(data) {
      this.setPropriedades({
        servicosAgendados: data,
      });
    },

    $setStoreServico(data) {
      this.setPropriedades({
        servico: data,
      });
    },

    $setStoreComanda(data) {
      this.setPropriedades({
        comanda: data,
      });
    },

    $setStoreImagensEmpresa(data) {
      this.setPropriedades({
        imagensEmpresa: data,
      });
    },

    $getStore(key) {
      return this.lodash.get(this.$store.getters.getPropriedades, key, "");
    },

    $clearStore(key) {
      try {
        this.$store.getters.getPropriedades[key] = undefined;
      } catch (e) {
        console.error(e, "$clearStore");
      }
    },
  },
};

export default mixin;
