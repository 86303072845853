<template>
  <CustomModal
    ref="componente-pagamentos-global"
    size="xl"
    hideHeaderClose
    noCloseOnBackdrop
    scrollable
    title="Pagamento"
  >
    <b-row class="container-informacoes-compra mt-0">
      <b class="texto-container-pagamento fonte-customizada">{{
        dadosComanda?.aguardandoPagamento?.name
      }}</b>

      <b class="texto-titulos-container-pagamento ml-1">
        {{ dadosComanda?.aguardandoPagamento?.description }}
      </b>

      <b class="texto-titulos-container-pagamento pt-3"
        >Valor total a pagar:
        <b class="texto-container-pagamento fonte-customizada">{{
          parseFloat(dadosComanda?.aguardandoPagamento?.value).toLocaleString(
            "pt-BR",
            {
              style: "currency",
              currency: "BRL",
            }
          )
        }}</b>
      </b>
    </b-row>

    <CustomForm
      rowContainerClass="container-detalhes-pagamento mt-1 pb-5"
      @change-form-values="gerarInformacoesPagamento"
      ref="formularioPagamento"
      :opcoes="inputsPagamento"
      :dados="{}"
    />

    <div v-if="inputsDetalhesPagamentoCartao.length > 0">
      <CustomForm
        rowContainerClass="container-detalhes-pagamento mt-1 pb-5"
        ref="formularioDetalhesPagamentoCartao"
        :opcoes="inputsDetalhesPagamentoCartao"
        :dados="{}"
      />
    </div>

    <div v-if="inputsDetalhesPagamentoBalcao.length > 0">
      <CustomForm
        rowContainerClass="container-detalhes-pagamento mt-1 pb-5"
        ref="formularioDetalhesPagamentoBalcao"
        :opcoes="inputsDetalhesPagamentoBalcao"
        :dados="{}"
      />
    </div>

    <div
      v-if="dadosCobranca.payload && inputsDetalhesPagamentoPix.length > 0"
      style=""
      class="text-center"
    >
      <CustomForm
        ref="formularioDetalhesPagamentoPix"
        :opcoes="inputsDetalhesPagamentoPix"
        :dados="{
          ...dadosCobranca.payload,
          cellphonePaymentLink: this.lodash.get(
            this.dadosComanda,
            'aguardandoPagamento.customer.cellphone',
            ''
          ),
        }"
        v-on:form-input-buttom-pressed="
          (toExecute, inputValue) => executarMetodoEval(toExecute, inputValue)
        "
      />
      <ImageThumb
        :hasDetailsModal="true"
        :rounded="false"
        :altura="300"
        :thumbnail="false"
        :largura="300"
        :src="`data:image/png;base64,${lodash.get(
          dadosCobranca,
          'payload.encodedImage',
          ''
        )}`"
      />
      <div class="mt-2">
        <Timer
          label="O código de pagamento expira em:"
          :endDate="
            new Date(lodash.get(dadosCobranca, 'payload.expirationDate', ''))
          "
        />
      </div>
    </div>

    <div v-if="dadosCobranca.qr_code" style="" class="text-center">
      <GroupButton
        rowContainerClass="d-contents"
        v-if="botoesAcaoModal.length > 0 && isMobile()"
        v-on:group-button-click="executarMetodoEval"
        :opcoes="botoesDetalhesPagamentoInifinitePay"
      />
      <div class="qr-code-section">
        <!-- <div class="payment-message" v-if="!isMobile()">
          {{ mensagemPagamento }}
        </div> -->
        <div class="qr-code-container">
          <div class="qr-code-header">{{ mensagemPagamento }}</div>
          <div class="qr-code-frame">
            <ImageThumb
              :hasDetailsModal="true"
              :rounded="false"
              :altura="300"
              :thumbnail="false"
              :largura="300"
              :src="`${lodash.get(dadosCobranca, 'qr_code', '')}`"
            />
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <GroupButton
        rowContainerClass="d-contents"
        v-if="botoesAcaoModal.length > 0"
        v-on:group-button-click="executarMetodoEval"
        :opcoes="botoesAcaoModal"
      />

      <GroupButton
        rowContainerClass="d-contents"
        v-if="botoesAcaoPagamentoCartao.length > 0"
        v-on:group-button-click="executarMetodoEval"
        :opcoes="botoesAcaoPagamentoCartao"
      />

      <GroupButton
        rowContainerClass="d-contents"
        v-if="botoesAcaoPagamentoBalcao.length > 0"
        v-on:group-button-click="executarMetodoEval"
        :opcoes="botoesAcaoPagamentoBalcao"
      />
      <GroupButton
        rowContainerClass="d-contents"
        v-if="botoesAcaoPagamentoPixInifinitePay.length > 0"
        v-on:group-button-click="executarMetodoEval"
        :opcoes="botoesAcaoPagamentoPixInifinitePay"
      />
    </template>
  </CustomModal>
</template>

<script>
import UtilsPagamento from "./utils/pagamento";
import SocketConsumer from "../../consumer";
import { EventBus } from "../event-bus";
import Mixin from "../core/Mixin";

import ServicosPagamento from "../services/payments";
import ServicosGenericos from "../services/generic";

export default {
  mixins: [Mixin],
  data() {
    return {
      conexaoSocketPagamento: null,
      dadosComanda: {},
      inputsPagamento: [],
      inputsDetalhesPagamentoCartao: [],
      inputsDetalhesPagamentoPix: [],
      botoesDetalhesPagamentoInifinitePay: [],
      inputsDetalhesPagamentoBalcao: [],
      botoesAcaoModal: [],
      botoesAcaoPagamentoCartao: [],
      botoesAcaoPagamentoBalcao: [],
      botoesAcaoPagamentoPixInifinitePay: [],
      mensagemPagamento: "",

      dadosCobranca: {},
    };
  },

  created() {
    EventBus.$on("componente-pagamentos-global", this.exibirModal);
  },

  mounted() {},

  methods: {
    async enviarDadosPagamentoPixInfinitePay() {
      try {
        await ServicosPagamento.enviarPagamentoPix({
          idCobranca: this.lodash.get(this.dadosCobranca, "id", ""),
          userId: this.$dadosSessao.user.id,
        });

        this.$notify({
          title: "Pagamento",
          text: "Finalizando recebimento...",
          duration: 5000,
          type: "success",
        });

        this.setLoading(true);

        this.processarRetornoPagamento({ id: 2 });
      } catch (e) {
        this.$notify({
          title: "Houve uma falha no recebimento",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      } finally {
        this.setLoading(false);
      }
    },

    abrirInifinitePay() {
      window.open(this.dadosCobranca.deeplink, "_blank");
    },

    async enviarDadosPagamentoCartao() {
      try {
        this.$notify({
          title: "Pagamento",
          text: "O seu pagamento está sendo processado, por favor aguarde...",
          duration: 5000,
          type: "success",
        });

        this.setLoading(true);
        let dados =
          this.$refs.formularioDetalhesPagamentoCartao.obterDadosFormulario();

        if (this.dadosCobranca.error) {
          throw `Houve um erro ao gerar a cobrança ${this.dadosCobranca.error}`;
        }

        await ServicosPagamento.enviarPagamentoCartao({
          idCobranca: this.lodash.get(this.dadosCobranca, "id", ""),
          userId: this.$dadosSessao.user.id,
          ...dados,
        });
      } catch (e) {
        this.$notify({
          title: "Não foi possível gerar a cobrança",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base.description",
            this.lodash.get(e, "response.data.errors.base", e)
          )}`,
          duration: 5000,
          type: "error",
        });

        if (
          this.lodash
            .get(e, "response.data.errors.base.code", "")
            .includes("invalid")
        ) {
          this.limparFormularios();
          this.fecharModal();

          this.$router.push({
            name: "Cadastro Usuario",
            params: {
              id: this.lodash.get(this, "$dadosSessao.user.id", ""),
              rotaAnterior: "Listagem planos",
            },
          });
        }
      } finally {
        this.setLoading(false);
      }
    },

    async enviarDadosPagamentoBalcao() {
      try {
        this.$notify({
          title: "Pagamento",
          text: "O seu pagamento está sendo processado, por favor aguarde...",
          duration: 5000,
          type: "success",
        });

        this.setLoading(true);
        // let dados =
        //   this.$refs.formularioDetalhesPagamentoBalcao.obterDadosFormulario();

        await ServicosPagamento.enviarPagamentoBalcao({
          idCobranca: this.lodash.get(this.dadosCobranca, "id", ""),
          userId: this.$dadosSessao.user.id,
          // ...dados,
        });

        this.processarRetornoPagamento({ id: 2 });
      } catch (e) {
        this.$notify({
          title: "Houve uma falha no pagamento",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base",
            "Tente novamente mais tarde ou entre em contato com o suporte"
          )}`,
          duration: 5000,
          type: "error",
        });
      } finally {
        this.setLoading(false);
      }
    },

    processarRetornoPagamento(retorno) {
      if (retorno && retorno.id != 2 && retorno.id != 3 && retorno.id != 6) {
        this.$notify({
          title: "Houve uma falha.",
          text: `Situação do pagamento: ${retorno.name}`,
          duration: 5000,
          type: "error",
        });

        return;
      }

      this.concluirPagamento();
    },

    concluirPagamento() {
      this.$setStoreComanda({
        aguardandoPagamento: {},
        pagamentoConcluido: {
          item: this.dadosComanda.aguardandoPagamento,
          detalhesPagamento: {
            ...this.dadosCobranca,
          },
        },
      });

      this.$notify({
        title: "Pagamento concluído.",
        text: ``,
        duration: 2000,
        type: "success",
      });

      EventBus.$emit("pagamento-concluido");

      this.fecharModal();
    },

    copiarParaAreaDeTransferencia(codigo) {
      navigator.clipboard.writeText(codigo);

      this.$notify({
        title: "Código PIX copiado.",
        text: ``,
        duration: 1000,
        type: "success",
      });
    },

    async enviarMensagemWhatsApp(numero) {
      await ServicosGenericos.enviarMensagemWhatsApp({
        numero: numero,
        mensagem: this.lodash.get(this.dadosCobranca, "payload.payload", ""),
      });

      this.$notify({
        title: "Mensagem enviada.",
        text: ``,
        duration: 1000,
        type: "success",
      });
    },

    async gerarInformacoesPagamento(dados) {
      try {
        this.setLoading(true);

        this.inputsDetalhesPagamentoCartao = [];
        this.inputsDetalhesPagamentoPix = [];
        this.inputsDetalhesPagamentoBalcao = [];
        this.botoesDetalhesPagamentoInifinitePay = [];
        this.botoesAcaoPagamentoCartao = [];
        this.botoesAcaoPagamentoBalcao = [];
        this.dadosCobranca = {};

        let tipoCobranca = this.lodash.get(
          this.dadosComanda,
          "aguardandoPagamento.tipoCobranca",
          ""
        );
        let faturaGerada;

        this.processarInputsPagamento(dados);

        if (tipoCobranca == "ASSINATURA_PLANO_CLIENTE") {
          faturaGerada = await ServicosPagamento.gerarPagamentoPlano({
            billingType: dados.billingType.id,
            userId: this.$dadosSessao.user.id,
            headquarterId: this.$dadosSessao.filial.id,
            idPlano: this.lodash.get(
              this.dadosComanda,
              "aguardandoPagamento.id",
              ""
            ),
          });
        }

        if (tipoCobranca == "ATENDIMENTO_FILA") {
          faturaGerada = await ServicosPagamento.gerarPagamentoAtendimentoFila({
            billingType: dados.billingType.id,
            // userId: this.$dadosSessao.user.id,
            headquarterId: this.$dadosSessao.filial.id,
            idAtendimentoFila: this.lodash.get(
              this.dadosComanda,
              "aguardandoPagamento.id",
              ""
            ),
          });
        }

        if (tipoCobranca == "ATENDIMENTO_AGENDADO") {
          faturaGerada =
            await ServicosPagamento.gerarPagamentoAtendimentoAgendado({
              billingType: dados.billingType.id,
              userId: this.lodash.get(
                this.dadosComanda,
                "aguardandoPagamento.customer.id",
                ""
              ),
              headquarterId: this.$dadosSessao.filial.id,
              idAtendimentoAgendado: this.lodash.get(
                this.dadosComanda,
                "aguardandoPagamento.id",
                ""
              ),
            });
        }

        this.dadosCobranca = this.lodash.get(faturaGerada, "data", {});

        this.criarCanalSocket();
      } catch (e) {
        this.fecharCanalSocket();

        this.$notify({
          title: "Não foi possível gerar a cobrança",
          text: `${this.lodash.get(
            e,
            "response.data.errors.base.description",
            this.lodash.get(e, "response.data.errors.base", e)
          )}`,
          duration: 5000,
          type: "error",
        });

        if (
          this.lodash
            .get(e, "response.data.errors.base.code", "")
            .includes("invalid")
        ) {
          this.limparFormularios();
          this.fecharModal();

          this.$router.push({
            name: "Cadastro Usuario",
            params: {
              id: this.lodash.get(this, "$dadosSessao.user.id", ""),
              rotaAnterior: "Listagem planos",
            },
          });
        }
      } finally {
        this.setLoading(false);
      }
    },

    processarInputsPagamento(dados) {
      this.inputsDetalhesPagamentoCartao = [];
      this.inputsDetalhesPagamentoPix = [];
      this.inputsDetalhesPagamentoBalcao = [];
      this.botoesDetalhesPagamentoInifinitePay = [];
      this.botoesAcaoPagamentoPixInifinitePay = [];
      this.mensagemPagamento = "";

      switch (dados.billingType.id) {
        case 1:
          this.mensagemPagamento =
            "Escaneie o código PIX para realizar o pagamento";

          this.botoesAcaoPagamentoPixInifinitePay =
            UtilsPagamento.obterBotoesAcaoPagamentoPixInfinitePay();

          return { message: "Pix" };
        case 2:
        case 3:
          this.botoesDetalhesPagamentoInifinitePay =
            UtilsPagamento.obterAcoesInfinitePay();

          this.mensagemPagamento =
            "Escaneie o código para abrir o aplicativo do Infinite Pay";
          return { message: "Processando pagamento via Inifite pay" };
        case 4:
          this.botoesAcaoPagamentoBalcao =
            UtilsPagamento.obterBotoesAcaoPagamentoBalcao();

          return { message: "Processando pagamento balcão" };
        case 5:
          this.inputsDetalhesPagamentoCartao =
            UtilsPagamento.obterInputsCartaoCredito();

          this.botoesAcaoPagamentoCartao =
            UtilsPagamento.obterBotoesAcaoPagamentoCartao();

          return { message: "Processando pagamento via Cartão de crédito" };
        case 6:
          this.inputsDetalhesPagamentoPix = UtilsPagamento.obterInputsPix();

          return { message: "Processando pagamento via PIX" };
        default:
          return { message: "Tipo de pagamento não suportado" };
      }

      // Era assim
      //     switch (dados.billingType.id) {
      //   case 3:
      //     this.inputsDetalhesPagamentoBalcao =
      //       UtilsPagamento.obterInputsPagamentoBalcao();

      //     this.botoesAcaoPagamentoBalcao =
      //       UtilsPagamento.obterBotoesAcaoPagamentoBalcao();

      //     return { message: "Processando pagamento balcão" };

      //   case 2:
      //     this.inputsDetalhesPagamentoPix = UtilsPagamento.obterInputsPix();

      //     return { message: "Processando pagamento via PIX" };

      //   case 1:
      //     this.inputsDetalhesPagamentoCartao =
      //       UtilsPagamento.obterInputsCartaoCredito();

      //     this.botoesAcaoPagamentoCartao =
      //       UtilsPagamento.obterBotoesAcaoPagamentoCartao();

      //     return { message: "Processando pagamento via Cartão de crédito" };

      //   default:
      //     return { message: "Tipo de pagamento não suportado" };
      // }
    },

    obterBotoesAcao() {
      this.botoesAcaoModal = UtilsPagamento.obterBotoesAcaoModalPagamento();
    },

    obterInputsTipoPagamento() {
      let ids = "1,2,3,4";
      let tipoCobranca = this.lodash.get(
        this.dadosComanda,
        "aguardandoPagamento.tipoCobranca",
        ""
      );
      if (tipoCobranca == "ASSINATURA_PLANO_CLIENTE") {
        ids = "5,6";
      }

      this.inputsPagamento = UtilsPagamento.obterInputsTipoPagamento(ids);
    },

    criarCanalSocket() {
      console.log("Starting connection to WebSocket Server");

      this.conexaoSocketPagamento = SocketConsumer.subscriptions.create(
        {
          channel: `PaymentStatusChannel`,
          id: this.lodash.get(this.dadosCobranca, "id", ""),
        },
        {
          connected: () => {
            console.log("Connected to PaymentStatusChannel");
          },
          disconnected: () => {
            console.log("Disconnected from PaymentStatusChannel");
          },
          received: (data) => {
            console.log("Received data: SOCKET", data);
            this.processarRetornoPagamento(data);
          },
          rejected: (data) => {
            console.log("Rejected connection:", data);
            this.fecharCanalSocket();
          },
        }
      );
    },

    fecharCanalSocket() {
      if (this.conexaoSocketPagamento) {
        this.conexaoSocketPagamento.unsubscribe(); // Fecha a conexão com o canal
        console.log("Canal desconectado");
      } else {
        console.log("Não há canal para desconectar");
      }
    },

    sendMessage() {
      console.log("Sending message");
      this.conexaoSocketPagamento.perform("notify", {
        message: "Test message from Vue component",
      });
    },

    async exibirModal(nomeModal = "componente-pagamentos-global") {
      if (this.$refs[nomeModal]) {
        await this.$nextTick();

        this.dadosComanda = this.$getStore("comanda");
        this.$refs[nomeModal].exibirModal();

        this.obterInputsTipoPagamento();
        this.obterBotoesAcao();
      }
    },

    async fecharModal(nomeModal = "componente-pagamentos-global") {
      if (this.$refs[nomeModal]) {
        this.$refs[nomeModal]?.fecharModal();
      }

      this.fecharCanalSocket();
      this.limparFormularios();
    },

    limparFormularios() {
      this.conexaoSocketPagamento = null;
      this.dadosComanda = {};
      this.inputsPagamento = [];
      this.inputsDetalhesPagamentoCartao = [];
      this.inputsDetalhesPagamentoPix = [];
      this.inputsDetalhesPagamentoBalcao = [];
      this.botoesAcaoModal = [];
      this.botoesAcaoPagamentoCartao = [];
      this.botoesAcaoPagamentoPixInifinitePay = [];
      (this.botoesAcaoPagamentoBalcao = []), (this.dadosCobranca = {});
    },

    limparFormularioPagamento() {
      this.inputsDetalhesPagamentoCartao = [];
      this.inputsDetalhesPagamentoPix = [];

      this.botoesAcaoModal = [];
      this.botoesAcaoPagamentoCartao = [];
      this.botoesDetalhesPagamentoInifinitePay = [];
    },
  },

  beforeDestroy() {
    this.fecharCanalSocket();
  },

  props: {
    colXL: {
      type: Number,
      required: false,
    },
    colLG: {
      type: Number,
      required: false,
    },
    colMD: {
      type: Number,
      required: false,
    },
    colSM: {
      type: Number,
      required: false,
    },
    cols: {
      type: Number,
      required: false,
    },
    titulo: {
      type: String,
      required: false,
    },
    subtitulo: {
      type: String,
      required: false,
    },
    containerClass: {
      type: String,
    },
  },
};
</script>

<style>
.texto-container-pagamento {
  font-size: 22px;
}

.texto-titulos-container-pagamento {
  font-size: 14px;
  font-weight: 300;
}
.container-informacoes-compra {
  padding: 10px; /* Espaçamento interno */
  background-color: var(--cor-cards) !important;
  border-radius: 5px;
}
.container-detalhes-pagamento {
  padding: 10px; /* Espaçamento interno */
  background-color: var(--cor-fundo) !important;
}
.qr-code-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  margin: 2rem auto;
  max-width: 400px;
}

.payment-message {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--cor-texto-primario);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  background: rgba(var(--cor-primaria-rgb), 0.05);
  border-radius: 12px;
  border: 1px solid rgba(var(--cor-primaria-rgb), 0.1);
}

.qr-code-container {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.06);
  position: relative;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
  text-align: center;
  animation: scaleIn 0.6s ease-out forwards;
}

.qr-code-header {
  background: #000;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  display: inline-block;
  animation: scaleIn 0.6s ease-out 0.2s both;
}

.qr-code-frame {
  position: relative;
  padding: 1rem;
  border: 2px solid #000;
  border-radius: 8px;
  display: inline-block;
  animation: scaleIn 0.6s ease-out 0.4s both;
}

.qr-code-frame::before,
.qr-code-frame::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #000;
}

.qr-code-frame::before {
  top: -5px;
  left: -5px;
  border-right: none;
  border-bottom: none;
}

.qr-code-frame::after {
  bottom: -5px;
  right: -5px;
  border-left: none;
  border-top: none;
}

.qr-code-container:hover {
  transform: translateY(-2px) scale(1.01);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.scan-frame {
  position: relative;
  padding: 8px;
  border: 2px solid var(--cor-primaria);
  border-radius: 12px;
}

.scan-frame::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid var(--cor-primaria);
  border-radius: 8px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
