class UtilsRotaLogin {
  obterEstruturaInputsLogin(modo, subdominio, idEmpresa, multiFilial) {
    let retorno = [];

    if (modo == 1) {
      //Login comum
      retorno = [
        {
          id: "login",
          label: "Login ou celular",
          model: "login",
          placeholder: "Digite o seu login ou celular",
          cols: 12,
        },
        {
          id: "password",
          label: "Senha",
          model: "password",
          inputType: "password",
          placeholder: "Digite sua senha",
          appendButton: true,
          scriptButtonGroup: `
                   this.inputType = this.inputType === "password" ? "text" : "password";
                   this.iconAppendButton = this.inputType === "password" ? "eye" : "eye-slash"`,
          iconAppendButton: "eye",
          cols: 12,
        },
      ];
    } else if (modo == 2) {
      //Cadastro simples / acesso
      retorno = [
        {
          id: "cellphone",
          label: "celular",
          model: "cellphone",
          inputType: "number",
          placeholder: "Digite seu celular",
          cols: 12,
          isInputMask: true,
          maskOptions: ["(##) ####-####", "(##) #####-####"],
        },
        {
          id: "name",
          label: "Nome",
          model: "name",
          placeholder: "Digite seu nome",
          cols: 12,
        },
      ];
      //Cadastro Completo
    } else if (modo == 3) {
      retorno = [
        {
          label: "Nome",
          id: "name",
          model: "name",
          placeholder: "",
          sm: "12",
          md: "12",
          lg: "12",
          xl: "12",
          cols: "12",
        },
        {
          label: "Celular",
          id: "cellphone",
          model: "cellphone",
          placeholder: "Digite o celular",
          sm: "12",
          md: "12",
          lg: "12",
          xl: "12",
          cols: "12",
          isInputMask: true,
          maskOptions: ["(##) ####-####", "(##) #####-####"],
        },
        {
          label: "E-mail",
          id: "email",
          model: "email",
          placeholder: "Digite o nome do produto",
          sm: "12",
          md: "12",
          lg: "12",
          xl: "12",
          cols: "12",
        },
        {
          label: "Senha",
          id: "password",
          model: "password",
          inputType: "password",
          appendButton: true,
          scriptButtonGroup: `
                   this.inputType = this.inputType === "password" ? "text" : "password";
                   this.iconAppendButton = this.inputType === "password" ? "eye" : "eye-slash"`,
          iconAppendButton: "eye",

          sm: "12",
          md: "12",
          lg: "12",
          xl: "12",
          cols: "12",
        },
        {
          label: "Confirmação senha",
          id: "passwordConfirmation",
          model: "passwordConfirmation",
          inputType: "password",
          appendButton: true,
          scriptButtonGroup: `
                   this.inputType = this.inputType === "password" ? "text" : "password";
                   this.iconAppendButton = this.inputType === "password" ? "eye" : "eye-slash"`,
          iconAppendButton: "eye",
          sm: "12",
          md: "12",
          lg: "12",
          xl: "12",
          cols: "12",
        },
      ];
    } else if (modo == 4) {
      //
      retorno = [
        {
          label: "Nome",
          id: "name",
          model: "name",
          placeholder: "",
          cols: "12",
        },
        {
          label: "Celular",
          id: "cellphone",
          model: "cellphone",
          placeholder: "Digite o seu número de celular",
          cols: "12",
          isInputMask: true,
          maskOptions: ["(##) ####-####", "(##) #####-####"],
        },
      ];
    } else if (modo == 5) {
      retorno = [
        {
          label: "Celular",
          id: "cellphone",
          model: "cellphone",
          placeholder: "Digite o número de celular vinculado a sua conta",
          cols: "12",
          isInputMask: true,
          maskOptions: ["(##) ####-####", "(##) #####-####"],
        },
      ];
    } else if (modo == 6) {
      retorno = [
        {
          label: "Senha",
          id: "password",
          model: "password",
          appendButton: true,
          scriptButtonGroup: `
                   this.inputType = this.inputType === "password" ? "text" : "password";
                   this.iconAppendButton = this.inputType === "password" ? "eye" : "eye-slash"`,
          iconAppendButton: "eye",
          cols: "12",
        },
        {
          label: "Confirmação de senha",
          id: "passwordConfirmation",
          model: "passwordConfirmation",
          appendButton: true,
          scriptButtonGroup: `
                   this.inputType = this.inputType === "password" ? "text" : "password";
                   this.iconAppendButton = this.inputType === "password" ? "eye" : "eye-slash"`,
          iconAppendButton: "eye",
          cols: "12",
        },
        {
          label: "Código de recuperação",
          id: "code",
          model: "code",
          cols: "12",
          inputType: "number",
        },
      ];
    } else if (modo === 7) {
      retorno = [
        {
          label: "E-mail",
          id: "email",
          model: "email",
          placeholder: "Informe seu e-mail Google",
          sm: "12",
          md: "12",
          lg: "12",
          xl: "12",
          cols: "12",
        },
      ];
    }

    if ((modo == 1 || modo == 4 || modo == 3 || modo == 7) && multiFilial) {
      retorno.push({
        label: "Filial",
        id: "filial",
        model: "filial",
        isMultiselect: true,
        multiple: false,
        labelsMultiselect: "name",
        placeholder: "Procure pelo nome da filial",
        optionsAxios: {
          method: "GET",
          url: `/externals/accounts/${idEmpresa}/headquarters/autocomplete`,
          params: {
            sort_direction: "desc",
            active: true,
          },
        },
        axiosPathData: "data.headquarters",
        axiosKeyFilter: "name",
      });
    }
    return retorno;
  }

  obterEstruturaBotoesLogin(tipoLogin) {
    return [
      {
        visible: tipoLogin == 7,
        iconProps: {
          icon: "",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Voltar",
        variant: "light",
        classButton: "",
        click: "this.mudarTipoLogin(1)",
      },
      // {
      //   visible: false,
      //   // visible:tipoLogin == 7,
      //   iconProps: {
      //     icon: "arrow-bar-right",
      //     variant: "light",
      //     "font-scale": "1.4",
      //   },
      //   containerProps: {
      //     cols: "6",
      //     xl: "6",
      //     lg: "6",
      //     md: "6",
      //     sm: "6",
      //   },
      //   buttonText: "Login google",
      //   variant: "primary",
      //   classButton: "text-white",
      //   click: "this.loginGoogle()",
      // },
      {
        visible: tipoLogin == 1,
        iconProps: {
          icon: "arrow-bar-right",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "12",
          xl: "12",
          lg: "12",
          md: "12",
          sm: "12",
        },
        buttonText: "Entrar",
        variant: "primary",
        classButton: "text-white",
        click: "this.realizarLogin(this.obterObjetoUsuarioPorTipo(1))",
      },

      {
        visible: tipoLogin == 1,
        iconProps: {
          icon: "person-plus",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "12",
          xl: "12",
          lg: "12",
          md: "12",
          sm: "12",
        },
        buttonText: "Primeiro acesso",
        variant: "dark",
        classButton: "text-white",
        click: "this.mudarTipoLogin(4)",
        // click:"this.mudarTipoLogin(3)",
      },
      {
        visible: false,
        containerProps: {
          cols: "12",
          xl: "12",
          lg: "12",
          md: "12",
          sm: "12",
        },
        buttonText: "Continuar sem cadastro",
        variant: "primary",
        classButton: "text-white",
        click: "this.mudarTipoLogin(4)",
      },
      // {
      //   visible: tipoLogin == 1,
      //   iconProps: {
      //     icon: "google",
      //     variant: "light",
      //     "font-scale": "1.2",
      //   },
      //   containerProps: {
      //     cols: "12",
      //     xl: "12",
      //     lg: "12",
      //     md: "12",
      //     sm: "12",
      //   },
      //   buttonText: "Login Google",
      //   variant: "link",
      //   classButton: "text-white border-0",
      //   click: "this.mudarTipoLogin(7)",
      //   // click:"this.mudarTipoLogin(3)",
      // },
      {
        visible: tipoLogin == 1,
        iconProps: {
          icon: "key-fill",
          variant: "light",
          "font-scale": "1.2",
        },
        containerProps: {
          cols: "12",
          xl: "12",
          lg: "12",
          md: "12",
          sm: "12",
        },
        buttonText: "Esqueci minha senha",
        variant: "dark",
        classButton: "text-white",
        click: "this.mudarTipoLogin(5)",
      },
      {
        visible: tipoLogin == 1, //MUDAR AQUI
        containerProps: {
          cols: "12",
          xl: "12",
          lg: "12",
          md: "12",
          sm: "12",
        },
        buttonText: "Estabelecimentos",
        variant: "transparent",
        classButton: "text-white mt-5",
        click: "this.selecaoEstabelecimento()",
      },
      {
        visible: tipoLogin == 3,
        iconProps: {
          icon: "",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Voltar",
        variant: "light",
        classButton: "",
        click: "this.mudarTipoLogin(1)",
      },
      {
        visible: tipoLogin == 3,
        iconProps: {
          icon: "person-plus",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Criar conta",
        variant: "primary",
        classButton: "text-white",
        click: "this.criarConta()",
      },
      {
        visible: tipoLogin == 4,
        iconProps: {
          icon: "",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Voltar",
        variant: "light",
        classButton: "",
        click: "this.mudarTipoLogin(1)",
      },
      {
        visible: tipoLogin == 4,
        iconProps: {
          icon: "arrow-bar-right",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Entrar",
        variant: "primary",
        classButton: "text-white",
        click: "this.criarConta(1)",
      },
      {
        visible: tipoLogin == 5,
        iconProps: {
          icon: "",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Voltar",
        variant: "light",
        classButton: "",
        click: "this.mudarTipoLogin(1)",
      },
      {
        visible: tipoLogin == 5,
        iconProps: {
          icon: "",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Continuar",
        variant: "primary",
        classButton: "text-white",
        click: "this.enviarCodigoRedefinicaoSenha()",
      },
      {
        visible: tipoLogin == 6,
        iconProps: {
          icon: "",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Voltar",
        variant: "light",
        classButton: "",
        click: "this.mudarTipoLogin(1)",
      },
      {
        visible: tipoLogin == 6,
        iconProps: {
          icon: "",
          variant: "light",
          "font-scale": "1.4",
        },
        containerProps: {
          cols: "6",
          xl: "6",
          lg: "6",
          md: "6",
          sm: "6",
        },
        buttonText: "Confirmar",
        variant: "primary",
        classButton: "text-white",
        click: "this.enviarRedefinicaoSenha()",
      },
    ];
  }
}

export default new UtilsRotaLogin();
