var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('b-col',_vm._b({},'b-col',_vm.propriedadesContainer,false),[_c('b-button',_vm._b({attrs:{"id":_vm.idGerado,"disabled":_vm.isButtonLoading},on:{"click":_vm.handleClick,"mouseover":_vm.handleMouseOver,"mouseout":_vm.handleMouseOut}},'b-button',_vm.propriedadesBotao,false),[_c('p',[(
          _vm.isButtonLoading &&
          (_vm.propriedadesIcone.icon || _vm.propriedadesIcone.class)
        )?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(_vm._s(_vm.buttonText)+" "),(_vm.propriedadesIcone.icon && !_vm.isButtonLoading)?_c('b-icon',_vm._b({},'b-icon',_vm.propriedadesIcone,false)):_vm._e(),(_vm.propriedadesIcone.class && !_vm.isButtonLoading)?_c('i',_vm._b({},'i',_vm.propriedadesIcone,false)):_vm._e()],1)]),(_vm.propriedadesHint.hasHint)?_c('b-popover',{attrs:{"target":_vm.idGerado,"triggers":"hover","placement":"top","custom-class":"dark-background"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('b',{staticClass:"fonte-customizada-dark"},[_vm._v(_vm._s(_vm.propriedadesHint.hintTitle))])]},proxy:true}],null,false,334978551)},[_c('b',{staticClass:"fonte-customizada-dark"},[_vm._v(_vm._s(_vm.propriedadesHint.hintText))])]):_vm._e(),_c('b-modal',{ref:"modal-confirmar-acao-button",attrs:{"size":"lg","header-bg-variant":"dark","body-bg-variant":"dark","footer-bg-variant":"dark","hide-header":true,"centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('CustomButton',{attrs:{"buttonText":"Voltar","variant":"light"},on:{"button-click":_vm.cancelAction}}),_c('CustomButton',{attrs:{"iconProps":{
          icon: 'check-circle-fill',
          variant: 'light',
        },"buttonText":"Confirmar","variant":"primary","classButton":"text-white"},on:{"button-click":_vm.confirmAction}})]},proxy:true}],null,false,2860350064),model:{value:(_vm.showConfirmation),callback:function ($$v) {_vm.showConfirmation=$$v},expression:"showConfirmation"}},[_c('div',{staticClass:"p-5 d-block text-center"},[_c('p',[_vm._v(_vm._s(_vm.confirmationText))])])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }