var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{key:_vm.componentKey,class:`${_vm.rowContainerClass}`,on:{"click":_vm.imagemSelecionada}},[_c('b-col',{attrs:{"sm":_vm.sm,"md":_vm.md,"lg":_vm.lg,"xl":_vm.xl,"cols":_vm.cols}},[_c('b-img',{staticClass:"cursor-pointer",attrs:{"rounded":_vm.rounded ? 'circle' : false,"thumbnail":_vm.thumbnail,"src":_vm.srcImagem && _vm.srcImagem != null
          ? _vm.srcImagem
          : require('../assets/sem_imagem.png'),"width":_vm.largura,"height":_vm.altura},on:{"error":_vm.handleImageError}}),_c('Hint',{attrs:{"hintTitle":"Detalhes","fontSize":2,"iconName":"","variant":"light","containerClass":"position-absolute top-0 end-0 m-2","showModal":false},scopedSlots:_vm._u([{key:"texto",fn:function(){return [_c('div',{staticClass:"image-details-container"},[_c('b-col',{staticClass:"text-center mb-3"},[_c('b-img-lazy',{attrs:{"rounded":'circle',"src":_vm.srcImagem && _vm.srcImagem != null
                  ? _vm.srcImagem
                  : require('../assets/sem_imagem.png'),"blank":true}})],1),(_vm.detailsData)?_c('div',{staticClass:"details-list"},_vm._l((_vm.detailsData),function(value,key){return _c('div',{key:key,staticClass:"detail-item"},[_c('strong',{staticClass:"detail-label text-capitalize"},[_vm._v(_vm._s(key))]),_c('span',{staticClass:"detail-value"},[_vm._v(_vm._s(value))])])}),0):_vm._e()],1)]},proxy:true}])})],1),_c('b-modal',{ref:"modal-detalhes-imagem",attrs:{"id":"modal-detalhes-imagem","size":"lg","modal-class":"custom-modal","centered":"","scrollable":"","hide-header":"","title":"Detalhes imagem","body-class":"container-detalhes-modal-options","footer-class":"container-detalhes-modal-options","header-class":"container-detalhes-modal-options"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('CustomButton',{attrs:{"buttonText":"Fechar","variant":"light"},on:{"button-click":_vm.fecharModal}})]},proxy:true}])},[_c('b-col',{staticClass:"text-center mt-3"},[_c('b-img-lazy',{attrs:{"src":_vm.srcImagem && _vm.srcImagem != null
            ? _vm.srcImagem
            : require('../assets/sem_imagem.png'),"blank":true}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }